import React, { useRef } from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import BannerDbCompany from 'components/BannerDbCompany'
import ButtonScrollDownload from 'components/ButtonScrollDownload'
import TableList from 'components/TableList'
import { numberFormat } from 'utils/numberFormat'
import shuffleStatic from 'utils/shuffleStatic'
import FeedbackButton from 'components/FeedbackButton'
import FAQ from 'components/FAQ'
import Contacts from 'components/Contacts'
import Columns from 'components/Columns'
import WithDbCompany from 'containers/WithDbCompany'
import DbList from 'components/DbList'
import Button from 'components/Button'
import Gallery from 'components/Gallery'
import route from 'utils/route'
import declension from 'utils/declension'
import DbPreview from 'components/DbPreview'
import Features from 'components/Features'
import Content from 'components/Content'
import ButtonAddToCart from 'components/ButtonAddToCart'
import Calc2 from 'components/Calc2'
import WithLocation from 'containers/WithLocation'
import Tooltip from 'components/Tooltip'
import Table from 'components/Table'
import useSiteMetadata from 'hooks/useSiteMetadata'
import { useNotificationCatalog } from 'hooks/useNotificationCatalog'
import Reviews from 'components/Reviews'
import Secondary from 'components/Secondary'

const Database = props => {
	const calcRef = useRef(null)
	const { location, images, pageContext } = props
	const { common, data, name, relatedDbs, totalDbs, linkDirectExample, reviews, tablesOrder } = pageContext
	const { statistics } = common
	const { databasesActual, siteUrl } = useSiteMetadata()

	// Показ уведомления о каталоге баз
	useNotificationCatalog(relatedDbs, totalDbs)

	const pictures = {}
	images.allFile.edges.forEach(({ node }) => {
		if (node.childImageSharp) pictures[node.name] = node.childImageSharp.fluid
	})

	const keywords = ['база, скачать, купить, excel', data.meta.keywords, 'телефоны, email, ексель'].join(', ')

	const thumbnail = pictures[name]
	const openGraph = {
		image: {
			url: siteUrl + thumbnail.src,
			width: thumbnail.presentationWidth,
			height: thumbnail.presentationHeight,
		},
	}

	data.faq.questions = data.faq.questions.map(({ title, description }) => {
		description = description.replace(/__COUNT_ROWS_FORMATTED__/g, data.common.rows.formatted)
		description = description.replace(/__COUNT_PHONES_FORMATTED__/g, data.common.phones.formatted)
		description = description.replace(/__COUNT_EMAILS_FORMATTED__/g, data.common.emails.formatted)
		description = description.replace(/__ACTUAL_DATE__/g, databasesActual) // TODO: move to .env-file
		description = description.replace(/__LINK_EXAMPLE__/g, linkDirectExample)
		description = description.replace(/__PRICE__/g, data.common.price)

		const priceOld = data.common.priceOld || null
		const discountPercent = priceOld ? Math.ceil(100 - (data.common.price * 100) / priceOld) : null

		description = description.replace(/__PRICE_OLD__/g, priceOld || '')
		description = description.replace(/__DISCOUNT_PERCENT__/g, discountPercent || '')
		description = description.replace(
			/__DISCOUNT_TEXT__/g,
			priceOld ? ` с учетом <strong>${discountPercent}%</strong> скидки. Акция действует несколько дней.` : ''
		)
		description = description.replace(/__DISCOUNT_TEXT_2_BEGIN__(.*?)__DISCOUNT_TEXT_2_END__/g, priceOld ? '$1' : '')

		return { title, description }
	})

	const howData = [
		<div key="how-search">
			<h3 dangerouslySetInnerHTML={{ __html: data.how.search.title }} />
			<div className="mb-lg">
				<p dangerouslySetInnerHTML={{ __html: data.how.search.text1 }} />
				<p dangerouslySetInnerHTML={{ __html: data.how.search.text2 }} />
				<p dangerouslySetInnerHTML={{ __html: data.how.search.text3 }} />
			</div>
			<div className="mb">
				<Gallery
					images={[
						{
							image: pictures[`${name}-search`],
							alt: data.how.search.image1,
							title: `${data.how.search.image1}. Открыть изображение`,
							caption: data.how.search.image1,
						},
					]}
				/>
			</div>
		</div>,
		<div key="how-filter">
			<h3 dangerouslySetInnerHTML={{ __html: data.how.filter.title }} />
			<div className="mb-lg">
				<p dangerouslySetInnerHTML={{ __html: data.how.filter.text1 }} />
				<p dangerouslySetInnerHTML={{ __html: data.how.filter.text2 }} />
				<p dangerouslySetInnerHTML={{ __html: data.how.filter.text3 }} />
			</div>
			<div className="mb">
				<Gallery
					images={[
						{
							image: pictures[`${name}-filter`],
							alt: data.how.filter.image1,
							title: `${data.how.filter.image1}. Открыть изображение`,
							caption: data.how.filter.image1,
						},
					]}
				/>
			</div>
		</div>,
		<div key="how-format">
			<h3 dangerouslySetInnerHTML={{ __html: data.how.format.title }} />
			<div className="mb-lg">
				<p dangerouslySetInnerHTML={{ __html: data.how.format.text1 }} />
				<p dangerouslySetInnerHTML={{ __html: data.how.format.text2 }} />
				<p dangerouslySetInnerHTML={{ __html: data.how.format.text3 }} />
				<p dangerouslySetInnerHTML={{ __html: data.how.format.text4 }} />
			</div>
			<div className="mb">
				<Gallery
					images={[
						{
							image: pictures[`${name}-email`],
							alt: data.how.format.image1,
							title: `${data.how.format.image1}. Открыть изображение`,
							caption: data.how.format.image1,
						},
						{
							image: pictures[`${name}-phone`],
							alt: data.how.format.image2,
							title: `${data.how.format.image2}. Открыть изображение`,
							caption: data.how.format.image2,
						},
					]}
				/>
			</div>
		</div>,
		<div key="how-delete">
			<h3 dangerouslySetInnerHTML={{ __html: data.how.delete.title }} />
			<div className="mb-lg">
				<p dangerouslySetInnerHTML={{ __html: data.how.delete.text1 }} />
				<p dangerouslySetInnerHTML={{ __html: data.how.delete.text2 }} />
			</div>
			<div className="mb-lg">
				<Gallery
					images={[
						{
							image: pictures[`unique-prepare`],
							alt: data.how.delete.image1,
							title: `${data.how.delete.image1}. Открыть изображение`,
							caption: data.how.delete.image1,
						},
						{
							image: pictures[`unique-success`],
							alt: data.how.delete.image2,
							title: `${data.how.delete.image2}. Открыть изображение`,
							caption: data.how.delete.image2,
						},
					]}
				/>
			</div>
		</div>,
	]
	const howOrder = shuffleStatic(name, howData.length)

	return (
		<Layout>
			{/* meta */}
			<SEO title={data.meta.title} description={data.meta.description} keywords={keywords} og={openGraph} />

			{/* banner */}
			<BannerDbCompany
				title={data.main.h1 || data.banner.title}
				alt={`${data.example.title} в Excel файле`}
				image={pictures['banner-excel']}
				// description={data.banner.description}
			>
				<div className="mb-lg">
					<p className="fs-h4 mb-xs">
						Актуальность: <strong className="text-upper">{databasesActual}</strong>
					</p>
					<p className="fs-h6 mb-xs">
						По&nbsp;всей России для рассылок <strong>email</strong>, <strong>whatsapp</strong>,{' '}
						<strong>telegram</strong> и&nbsp;др.
					</p>
					{/*<p className="fs-h5">*/}
					{/*	По&nbsp;всей России за&nbsp;<strong>2023&nbsp;год</strong>*/}
					{/*</p>*/}
				</div>

				<div className="ib">
					<Tooltip placement="bottom" animation="zoom" overlay="Скачайте и ознакомьтесь с&nbsp;базой" defaultVisible>
						<div className="strong mb-sm sm_mb-0">
							<Button
								theme="primary"
								icon="excel"
								isLinkNative
								to={linkDirectExample}
								download
								rel="nofollow noopener noreferrer"
								title={`Скачать демонстрационную версию базы ${data.names.mn.rd} в Excel-файле`}
							>
								Скачать демо
							</Button>
						</div>
					</Tooltip>
				</div>
				<div className="ib ml-sm mr-sm">
					<ButtonAddToCart
						name={name}
						addProps={{ theme: 'success', children: 'Купить', className: 'strong' }}
						successProps={{ children: 'В корзине', className: 'strong' }}
					/>
				</div>
			</BannerDbCompany>

			{/* presentation */}
			<Sector>
				<Grid fluid className="mb-xl">
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<h2 className="mb">{data.presentation.title}</h2>
							<div className="fs-h4">
								<p dangerouslySetInnerHTML={{ __html: data.presentation.description }} />
								<p>
									Ознакомьтесь с&nbsp;
									<a
										href={linkDirectExample}
										download
										rel="nofollow noopener noreferrer"
										title={`Скачать демонстрационную версию базы ${data.names.mn.rd} в Excel-файле`}
									>
										демо-версией базы
									</a>
								</p>
							</div>
						</Col>
					</Row>
				</Grid>
				<div className="mb-xl">
					<Features
						columns={3}
						isSector={false}
						data={[
							{
								url: '/',
								icon: 'tablePen',
								title: `${data.common.rows.formatted} записей`,
								description: data.presentation.features.count,
							},
							{
								url: '/',
								icon: 'envelopeLetter',
								title: `${data.common.emails.formatted} email-адресов`,
								description: data.presentation.features.emails,
							},
							{
								url: '/',
								icon: 'phoneCloud',
								title: `${data.common.phones.formatted} телефонов`,
								description: data.presentation.features.phones,
							},
						]}
					/>
				</div>
				<div className="text-center">
					<ButtonScrollDownload
						scrollRef={calcRef}
						link={linkDirectExample}
						names={data.names}
						buttonThemeAfter="success"
						delay={2 * 1000}
					/>
				</div>
			</Sector>

			{/* preview */}
			<Sector id="example" color="gray" title={data.example.title} titleCentered withoutContent>
				<Content className="text-center">
					<Row>
						<Col lgOffset={1} lg={10}>
							<div className="text-lg mb" dangerouslySetInnerHTML={{ __html: data.example.text.before }} />
						</Col>
					</Row>
					<div className="mb-lg" dangerouslySetInnerHTML={{ __html: data.example.text.after }} />
				</Content>
				<div className="mb-lg">
					<DbPreview name={name} texts={data.example.preview} tablesOrder={tablesOrder} />
				</div>
				<Content>
					<Row>
						<Col lgOffset={1} lg={10}>
							<div className="text-center">
								<p className="text-lg mb">
									Пример содержит не более 2000 строк.
									<br />
									Чтобы посмотреть всю базу, скачайте ее на компьютер
								</p>
								<ButtonScrollDownload
									scrollRef={calcRef}
									link={linkDirectExample}
									names={data.names}
									buttonThemeAfter="success"
									delay={2 * 1000}
								/>
							</div>
						</Col>
					</Row>
				</Content>
			</Sector>

			{/* calc */}
			<div ref={calcRef}>
				<Sector>
					<Calc2
						schema={{
							name: data.calc.schema.name,
							description: data.calc.schema.description,
							price: data.common.price,
							image: siteUrl + pictures[name].originalImg,
							sku: name,
						}}
						title="Что хотите сделать?"
						rounding={1}
						initialValues={{
							type: 'cart',
							source: siteUrl + location.pathname,
						}}
						services={[
							{
								field: {
									type: 'radio',
									name: 'type',
									value: 'cart',
									children: `Купить полную версию базы`,
									helper: '',
									size: 'lg',
								},
								title: 'Стоимость',
								price: data.common.price,
								priceOld: data.common.priceOld,
								isShowPrice: true,
								inner: {
									component: {
										type: 'custom',
										render: () => {
											return (
												<p className="mb">
													<ButtonAddToCart name={name} addProps={{ theme: 'success' }} />
												</p>
											)
										},
									},
								},
							},
							{
								field: {
									type: 'radio',
									name: 'type',
									value: 'consultation',
									children: `Получить консультацию`,
									helper: '',
									size: 'lg',
								},
								title: 'Спрашивайте',
								inner: {
									component: {
										type: 'contactForm',
										props: {
											fields: [
												{
													type: 'name',
													name: 'name',
													placeholder: 'Ваше имя *',
													required: true,
												},
												{
													type: 'email',
													name: 'email',
												},
												{
													type: 'phone',
													name: 'phone',
												},
												{
													type: 'textarea',
													name: 'message',
													placeholder: 'Какой у вас вопрос?',
												},
											],
											buttonTitle: 'Отправить',
											buttonTitleSuccess: 'Отправлено',
											buttonTheme: 'success',
											buttonIcon: 'check',
											textSuccess: 'Скоро свяжемся с вами. Ожидайте пожалуйста',
										},
									},
									textBefore: (
										// <p>Расскажем все про базу Если у вас есть вопросы, мы свяжемся с вами и расскажем все про базу</p>
										<p>Расскажем все про базу</p>
									),
								},
								endpoint: '/contact',
							},
							{
								field: {
									type: 'radio',
									name: 'type',
									value: 'other-base',
									children: `Заказать другую базу`,
									helper: '',
									size: 'lg',
								},
								title: 'Стоимость',
								price: 1790,
								pricePrefix: 'от ',
								isShowPrice: true,
								inner: {
									component: {
										type: 'contactForm',
										props: {
											fields: [
												{
													type: 'name',
													name: 'name',
													placeholder: 'Ваше имя *',
													required: true,
												},
												{
													type: 'email',
													name: 'email',
												},
												{
													type: 'phone',
													name: 'phone',
												},
												{
													type: 'textarea',
													name: 'message',
													placeholder: 'Какие компании вас интересуют?',
												},
											],
											buttonTitle: 'Отправить',
											buttonTitleSuccess: 'Отправлено',
											buttonTheme: 'success',
											buttonIcon: 'check',
											textSuccess: 'Скоро свяжемся с вами. Ожидайте пожалуйста',
										},
									},
									textBefore: (
										<>
											<p>Это предварительная стоимость</p>
											<p>Цена может быть больше или меньше после индивидуальной оценки</p>
										</>
									),
								},
								endpoint: '/contact',
							},
						]}
					/>
				</Sector>
			</div>

			{/* faq */}
			<Sector title={data.faq.title} color="gray" titleCentered>
				<div className="mb-lg">
					<FAQ
						currentIndexes={[0]}
						isNumbers
						items={data.faq.questions.map(({ title, description }) => ({
							title,
							description: <div className="p-no-last" dangerouslySetInnerHTML={{ __html: description }} />,
						}))}
					/>
				</div>
				<p className="text-lg mb-lg text-center" dangerouslySetInnerHTML={{ __html: data.faq.quote }} />
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Задать вопрос
					</FeedbackButton>
				</div>
			</Sector>

			{/* reviews */}
			<Sector id="reviews" title="Отзывы клиентов" titleCentered>
				<Row className="text-center mb-lg">
					<Col lgOffset={1} lg={10}>
						<p className="fs-h4" dangerouslySetInnerHTML={{ __html: data.reviews.description }} />
						<Secondary tag="p">{data.reviews.personal}</Secondary>
					</Col>
				</Row>
				<Reviews limit={2} showBy={20} reviews={reviews} />
			</Sector>

			{/* dbs */}
			<Sector color="blue" title={data.dbs.title} isGradient titleCentered>
				<div className="text-center">
					<div className="fs-h4 mb-lg">
						<p>
							<span dangerouslySetInnerHTML={{ __html: data.dbs.description.sentence1 }} />{' '}
							<strong>
								{totalDbs} {declension(totalDbs, 'баз', 'базу', 'базы')} компаний
							</strong>
							.<br />
							<span dangerouslySetInnerHTML={{ __html: data.dbs.description.sentence2 }} />
						</p>
					</div>
					<div className="mb-lg">
						<DbList dbs={relatedDbs} negative />
					</div>
					<Button to={route.custom({ slug: 'dbs' })} isLink theme="light">
						Посмотреть все базы
					</Button>
				</div>
			</Sector>

			{/* content */}
			<Sector color="gray" title={data.content.title} titleCentered>
				<div className="static-text" dangerouslySetInnerHTML={{ __html: data.content.text }} />
			</Sector>

			{/* structure */}
			<Sector title={data.structure.title} titleCentered>
				<Row className="text-center">
					<Col lgOffset={1} lg={10}>
						<p className="text-lg" dangerouslySetInnerHTML={{ __html: data.structure.text.before.primary }} />
						<p className="mb-lg" dangerouslySetInnerHTML={{ __html: data.structure.text.before.secondary }} />
					</Col>
				</Row>
				<TableList items={data.structure.table} />
			</Sector>

			{/* count */}
			<Sector id="count" color="gray" title={data.count.title} titleCentered>
				<Row>
					<Col lgOffset={1} lg={10}>
						<p className="text-lg text-center mb-lg" dangerouslySetInnerHTML={{ __html: data.count.text.before }} />
						<div className="mb-lg">
							{/* prettier-ignore */}
							<Table
								columns={[
									{
										key: 'name',
										title: null,
									},
									{
										key: 'websites',
										title: <>Таблица &laquo;Веб-сайты&raquo;</>,
										className: 'text-nowrap',
									},
									{
										key: 'gis',
										title: <>Таблица &laquo;Справочник&raquo;</>,
										className: 'text-nowrap',
									},
								]}
								source={[
									{
										name: <span className="strong">Количество записей</span>,
										websites: (<><span className="hidden-no-mobile">Таблица &laquo;Веб-сайты&raquo;:</span> {numberFormat(statistics.sites.count, { isThousand: true })}</>),
										gis: (<><span className="hidden-no-mobile">Таблица &laquo;Справочник&raquo;:</span> {numberFormat(statistics.gis.count, { isThousand: true })}</>),
									},
									{
										name: <span className="strong">Мобильных телефонов</span>,
										websites: (<><span className="hidden-no-mobile">Таблица &laquo;Веб-сайты&raquo;:</span> {numberFormat(statistics.sites.phonesMobile, { isThousand: true })}</>),
										gis: (<><span className="hidden-no-mobile">Таблица &laquo;Справочник&raquo;:</span> {numberFormat(statistics.gis.phonesMobile, { isThousand: true })}</>),
									},
									{
										name: <span className="strong">Городских телефонов</span>,
										websites: (<><span className="hidden-no-mobile">Таблица &laquo;Веб-сайты&raquo;:</span> {numberFormat(statistics.sites.phonesCity, { isThousand: true })}</>),
										gis: (<><span className="hidden-no-mobile">Таблица &laquo;Справочник&raquo;:</span> {numberFormat(statistics.gis.phonesCity, { isThousand: true })}</>),
									},
									{
										name: <span className="strong">Email-адресов</span>,
										websites: (<><span className="hidden-no-mobile">Таблица &laquo;Веб-сайты&raquo;:</span> {numberFormat(statistics.sites.emails, { isThousand: true })}</>),
										gis: (<><span className="hidden-no-mobile">Таблица &laquo;Справочник&raquo;:</span> {numberFormat(statistics.gis.emails, { isThousand: true })}</>),
									},
								]}
							/>
						</div>
						<p className="text-center" dangerouslySetInnerHTML={{ __html: data.count.text.after }} />
					</Col>
				</Row>
			</Sector>

			{/* keys */}
			<Sector title={data.keys.title} titleCentered>
				<Row className="text-center mb-lg text-lg">
					<Col lgOffset={1} lg={10}>
						<p dangerouslySetInnerHTML={{ __html: data.keys.description }} />
					</Col>
				</Row>
				<Columns count={4}>
					<ul>
						{data.keys.items.map((item, index) => (
							<li key={index}>{item}</li>
						))}
					</ul>
				</Columns>
			</Sector>

			{/* order */}
			<Sector color="blue" title={data.order.title} isGradient>
				<div className="mb-lg" dangerouslySetInnerHTML={{ __html: data.order.description }} />
				<p>
					<FeedbackButton theme="light" size="lg">
						Заказать парсинг
					</FeedbackButton>
				</p>
			</Sector>

			{/* how */}
			{data.how && (
				<Sector color="gray" title={data.how.title}>
					<div className="static-text">
						<div className="mb">
							<p dangerouslySetInnerHTML={{ __html: data.how.text }} />
							<h3 dangerouslySetInnerHTML={{ __html: data.how.methods.title }} />
							<p dangerouslySetInnerHTML={{ __html: data.how.methods.text }} />
							<h4 dangerouslySetInnerHTML={{ __html: data.how.methods.computer.title }} />
							<p dangerouslySetInnerHTML={{ __html: data.how.methods.computer.text }} />
							<h4 dangerouslySetInnerHTML={{ __html: data.how.methods.online.title }} />
							<p dangerouslySetInnerHTML={{ __html: data.how.methods.online.text1 }} />
							<p dangerouslySetInnerHTML={{ __html: data.how.methods.online.text2 }} />
						</div>
						{howOrder.map(index => howData[index])}
						<blockquote className="blockquote" dangerouslySetInnerHTML={{ __html: data.how.quote }} />
					</div>
				</Sector>
			)}

			<Contacts />
		</Layout>
	)
}

export default WithDbCompany(WithLocation(Database))
