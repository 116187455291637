import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './BannerDbCompany.module.scss'
import Content from 'components/Content'
import Img from 'gatsby-image'

const b = bem.bannerDbCompany(css)

const BannerDbCompany = ({ title, description, children, image, alt }) => {
	const desc = description ? description.trim() : null
	return (
		<div className={b()}>
			<div className={b('inner')}>
				<Content className={b('content')}>
					<div className={b('content-inner')}>
						<div className={b('text')}>
							<h1 dangerouslySetInnerHTML={{ __html: title }} />
							{desc && <div className={b('description', 'fs-h4 no-last')} dangerouslySetInnerHTML={{ __html: desc }} />}
							{children && <div className={b('children')}>{children}</div>}
						</div>

						<div className={b('image-wrapper')}>
							<div className={b('image-inner')}>
								<Img className={b('gatsby-image')} fluid={image} alt={alt} loading="auto" />
							</div>
						</div>
					</div>
				</Content>
			</div>
		</div>
	)
}

BannerDbCompany.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	children: PropTypes.any,
	alt: PropTypes.string.isRequired,
	image: PropTypes.any.isRequired,
}

export default BannerDbCompany
