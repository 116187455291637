import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const WithDbCompany = Component => {
	const Enhanced = props => {
		// prettier-ignore
		const images = useStaticQuery(graphql`
			query {
				allFile(
					 filter: {
						  sourceInstanceName: { eq: "images" },
						  relativeDirectory: { eq: "db-company" },
                    name: {
                        nin: [
									 "notary",
									 "notary-email",
									 "notary-filter",
									 "notary-phone",
									 "notary-search",
									 
                            "copying",
                            "copying-email",
                            "copying-filter",
                            "copying-phone",
                            "copying-search",
									 
                            "education-music",
                            "education-music-email",
                            "education-music-filter",
                            "education-music-phone",
                            "education-music-search",
									 
                            "evacuator",
                            "evacuator-email",
                            "evacuator-filter",
                            "evacuator-phone",
                            "evacuator-search",
									 
                            "meat-shop",
                            "meat-shop-email",
                            "meat-shop-filter",
                            "meat-shop-phone",
                            "meat-shop-search",
									 
                            "media-service",
                            "media-service-email",
                            "media-service-filter",
                            "media-service-phone",
                            "media-service-search",
									 
                            "mobile-accessories",
                            "mobile-accessories-email",
                            "mobile-accessories-filter",
                            "mobile-accessories-phone",
                            "mobile-accessories-search",
									 
                            "museum",
                            "museum-email",
                            "museum-filter",
                            "museum-phone",
                            "museum-search",
									 
                            "shoes-service",
                            "shoes-service-email",
                            "shoes-service-filter",
                            "shoes-service-phone",
                            "shoes-service-search",
									 
                            "stationery",
                            "stationery-email",
                            "stationery-filter",
                            "stationery-phone",
                            "stationery-search",
									 
                            "tire-fitting",
                            "tire-fitting-email",
                            "tire-fitting-filter",
                            "tire-fitting-phone",
                            "tire-fitting-search",
								]
                    }
					 }
				) {
					edges {
						node {
							name
							childImageSharp {
								fluid(maxWidth: 930) {
									...GatsbyImageSharpFluid
									originalImg
									presentationWidth
									presentationHeight
								}
							}
						}
					}
				}
			}
		`)
		return <Component {...props} images={images} />
	}

	return Enhanced
}

export default WithDbCompany
