import React, { useState } from 'react'
import bem from 'bem'
import css from './DbPreview.module.scss'
import DbIframe from './components/DbIframe'
import Icon from 'components/Icon'
import LazyLoad from 'react-lazy-load'
import Content from 'components/Content'
import Tooltip from 'components/Tooltip'
import Secondary from 'components/Secondary'

const b = bem.dbPreview(css)

const DbPreview = props => {
	const { name, texts, tablesOrder } = props
	const height = 500

	const tables = {
		sites: {
			name: 'Веб-сайты',
			file: 'Веб-сайты',
			title: texts.website.title,
			text: (
				<div className="p-no-last">
					<p>{texts.website.text}</p>
					<Secondary dangerouslySetInnerHTML={{ __html: texts.website.secondary }} />
				</div>
			),
		},
		gis: {
			name: 'Справочник',
			file: 'Справочник',
			title: texts.gis.title,
			text: (
				<div className="p-no-last">
					<p>{texts.gis.text}</p>
					<Secondary dangerouslySetInnerHTML={{ __html: texts.gis.secondary }} />
				</div>
			),
		},
		validator: {
			name: 'Email-валидатор. Отчет',
			file: 'Email-валидатор. Отчет',
			title: texts.validator.title,
			text: (
				<div className="p-no-last">
					<p>{texts.validator.text}</p>
					<Secondary dangerouslySetInnerHTML={{ __html: texts.validator.secondary }} />
				</div>
			),
		},
	}
	const pages = tablesOrder.map(table => tables[table])

	const [currentPage, setCurrentPage] = useState(pages[0].file)

	return (
		<div className={b()}>
			<div className={b('lists')} style={{ height }}>
				<div className={b('list')}>
					<LazyLoad offsetTop={250} height={height}>
						<DbIframe key={`iframe-${currentPage}`} name={name} page={currentPage} />
					</LazyLoad>
				</div>
			</div>
			<div className={b('buttons', 'mb-xl')}>
				<div className={b('pseudo')}>
					<Icon type="arrowPrev" size={16} className={b('pseudo-button', { type: 'prev' })} />
					<Icon type="arrowNext" size={16} className={b('pseudo-button', { type: 'next' })} />
					<Icon type="menu" size={16} className={b('pseudo-button', { type: 'menu' })} />
				</div>
				<Tooltip placement="bottom" animation="zoom" overlay="Можно переключаться по таблицам" defaultVisible>
					<div>
						{pages.map(({ name, file, tooltip }, index) => (
							<button
								key={`button-${name}`}
								type="button"
								className={b('button', { 'is-active': currentPage === file })}
								onClick={() => {
									setCurrentPage(file)
								}}
							>
								{name}
							</button>
						))}
					</div>
				</Tooltip>
			</div>
			<Content>
				<div className={b('tabs-info')}>
					<ul>
						{pages.map(({ name, title, text }) => (
							<li key={name}>
								<h3 className={b('tab-header', 'h5')}>
									<span className={b('tab-name')}>{name}</span>{' '}
									<div className={b('tab-title')}>
										<span dangerouslySetInnerHTML={{ __html: `— ${title}` }} />
									</div>
								</h3>
								<div className={b('tabs-info-text')}>{text}</div>
							</li>
						))}
					</ul>
				</div>
			</Content>
		</div>
	)
}

export default DbPreview
